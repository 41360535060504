import React from "react";
import { useIntl } from "react-intl";
import Banner from "../../components/banner";
import SeoHelmet from "../../components/seo-helmet";


const LegalMention = (
    {
    }) => {
    const intl = useIntl();
    
    return(
        <>
            <SeoHelmet
                title={intl.formatMessage({ id: "seo-title-mentions-legals", defaultMessage: "Maytain | Mentions légales"})}
                description={intl.formatMessage({id:"seo-description-mentions-legals", defaultMessage:"Mentions légales de Maytain"})}
                canonical="/mentions-legals"
            />
            <Banner title={intl.formatMessage({id: "legal-mention-main-title", defaultMessage: "Mention Légale"})} />
            <section className="basic">
                <h3>{intl.formatMessage({id: "legal-mention-subtitle-1", defaultMessage: "Éditeur du site : "})}</h3>
                <p>{intl.formatMessage({id: "legal-mention-text-1", defaultMessage: "Agence Immobilière Maytain sarl "})}</p>
                <p>{intl.formatMessage({id: "legal-mention-text-2", defaultMessage: "Société enregistrée sous le numéro CHE-107.027.874"})}</p>
                <p>{intl.formatMessage({id: "legal-mention-text-3", defaultMessage: "Adresse : Route du Village 15, 1875 Morgins "})}</p>
                <p>{intl.formatMessage({id: "legal-mention-text-4", defaultMessage: "Téléphone : 024 477 24 21 "})}</p>
                <p>E-mail : info@maytain.com </p>
                <p>{intl.formatMessage({id: "legal-mention-text-5", defaultMessage: "Directeur de la publication : Camille Renevier"})}</p>

                <h3>{intl.formatMessage({id: "legal-mention-subtitle-2", defaultMessage: "Hébergement du site : "})}</h3>
                <p>C.T. Creative Technologies SA</p>
                <p>{intl.formatMessage({id: "legal-mention-text-6", defaultMessage: "Adresse : Rue des vorziers 8, 1920 Martigny"})}</p>
                <p>{intl.formatMessage({id: "legal-mention-text-7", defaultMessage: "Téléphone : 027 721 78 81"})}</p>

                <h3>{intl.formatMessage({id: "legal-mention-subtitle-3", defaultMessage: "Propriété intellectuelle : "})}</h3>
                <p>{intl.formatMessage({id: "legal-mention-text-8", defaultMessage: "Tous les contenus présents sur le site www.maytain.com (textes, images, graphiques, logos, vidéos, etc.) sont protégés par les lois en vigueur sur la propriété intellectuelle. Toute reproduction, distribution, modification ou utilisation de ces contenus sans autorisation écrite préalable est strictement interdite."})}</p>

                <h3>{intl.formatMessage({id: "legal-mention-subtitle-4", defaultMessage: "Responsabilité : "})}</h3>
                <p>{intl.formatMessage({id: "legal-mention-text-9", defaultMessage: "Malgré tous les efforts pour assurer l'exactitude des informations diffusées sur ce site, Maytain ne saurait être tenu responsable des éventuelles erreurs ou omissions. Maytain décline également toute responsabilité concernant les dommages directs ou indirects liés à l’utilisation du site."})}</p>

                <p>{intl.formatMessage({id: "legal-mention-text-10", defaultMessage: "Pour toute question ou réclamation, veuillez nous contacter à l’adresse info@maytain.com"})}</p>
            </section>
        </>
    );

};

export default LegalMention;