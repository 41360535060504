import React from "react";
import { useIntl } from "react-intl";
import Banner from "../../components/banner";
import SeoHelmet from "../../components/seo-helmet";


const PrivacyPolicy = (
    {
    }) => {
    const intl = useIntl();
    
    return(
        <>
            <SeoHelmet
                title={intl.formatMessage({ id: "seo-title-privacy-policy", defaultMessage: "Maytain | Politique de protection des données"})}
                description={intl.formatMessage({id:"seo-description-privacy-policy", defaultMessage:"Politique de protection des données de Maytain"})}
                canonical="/politique-de-confidentialite"
            />
            <Banner title={intl.formatMessage({id: "privacy-policy-main-title", defaultMessage: "Protection des Données"})} />
            <section className="basic">
                <h3>{intl.formatMessage({id: "privacy-policy-subtitle-1", defaultMessage: "Collecte et utilisation des données personnelles :"})}</h3> 
                <p>{intl.formatMessage({id: "privacy-policy-text-1", defaultMessage: "Les données personnelles collectées sur le site www.maytain.com (formulaires de contact, inscriptions, etc.) sont traitées dans le respect des dispositions légales en matière de protection des données, notamment le RGPD (Règlement Général sur la Protection des Données)."})}</p>

                <p>{intl.formatMessage({id: "privacy-policy-text-2", defaultMessage: "Les informations collectées sont utilisées uniquement dans le cadre des services proposés par le site, tels que :"})}</p>
                <ul>
                    <li>{intl.formatMessage({id: "privacy-policy-list-1", defaultMessage: "La gestion des demandes de renseignements."})}</li>
                    <li>{intl.formatMessage({id: "privacy-policy-list-2", defaultMessage: "La gestion des réservations ou des ventes immobilières."})}</li>
                    <li>{intl.formatMessage({id: "privacy-policy-list-3", defaultMessage: "L’envoi d’informations commerciales (si consentement explicite)."})}</li>
                </ul>
                <p>{intl.formatMessage({id: "privacy-policy-text-3", defaultMessage: "Vos données ne seront en aucun cas transmises à des tiers sans votre accord préalable."})}</p>

                <h3>{intl.formatMessage({id: "privacy-policy-subtitle-2", defaultMessage: "Droits des utilisateurs : "})}</h3>
                <p>{intl.formatMessage({id: "privacy-policy-text-4", defaultMessage: "Conformément au RGPD, vous disposez des droits suivants sur vos données :"})}</p>
                <ul>
                    <li>{intl.formatMessage({id: "privacy-policy-list-4", defaultMessage: "Droit d'accès : obtenir une copie des données personnelles que nous détenons."})}</li>
                    <li>{intl.formatMessage({id: "privacy-policy-list-5", defaultMessage: "Droit de rectification : demander la correction de vos informations."})}</li>
                    <li>{intl.formatMessage({id: "privacy-policy-list-6", defaultMessage: "Droit à l’effacement : demander la suppression de vos données personnelles."})}</li>
                    <li>{intl.formatMessage({id: "privacy-policy-list-7", defaultMessage: "Droit d’opposition : vous opposer à leur utilisation à des fins marketing."})}</li>
                    <li>{intl.formatMessage({id: "privacy-policy-list-8", defaultMessage: "Droit à la portabilité : recevoir vos données dans un format structuré."})}</li>
                </ul>

                <p>{intl.formatMessage({id: "privacy-policy-text-5", defaultMessage: "Pour exercer ces droits, contactez-nous à l’adresse suivante : info@maytain.com"})}</p>

                <h3>{intl.formatMessage({id: "privacy-policy-subtitle-3", defaultMessage: "Sécurité des données : "})}</h3>
                <p>{intl.formatMessage({id: "privacy-policy-text-6", defaultMessage: "Maytain met en œuvre toutes les mesures techniques et organisationnelles nécessaires pour garantir la sécurité de vos données et prévenir tout accès non autorisé, perte ou altération."})}</p>

                <h3>{intl.formatMessage({id: "privacy-policy-subtitle-4", defaultMessage: "Cookies : "})}</h3>
                <p>{intl.formatMessage({id: "privacy-policy-text-7", defaultMessage: "Le site www.maytain.com utilise des cookies pour améliorer l’expérience utilisateur. Vous pouvez configurer votre navigateur pour refuser ou limiter l’utilisation des cookies. Certaines fonctionnalités du site peuvent être affectées en cas de désactivation."})}</p>

                <p>{intl.formatMessage({id: "privacy-policy-text-8", defaultMessage: "Pour toute question relative à la protection de vos données, veuillez nous écrire à : info@maytain.com"})}</p>
            </section>
        </>
    );

};

export default PrivacyPolicy;