import React from "react";
import { RouteObject, useRoutes, } from "react-router";
import { allLocales } from "@shared/locales";
import Sale from "./pages/articles/sale"
import Home from "./pages/home/home";
import Contact from "./pages/contact/contact";
import { AnnualLocation, SeasonalLocation, WeeklyLocation } from "./pages/articles/location";
import Administration from "./pages/administration/administration";
import Managment from "./pages/managment/managment";
import About from "./pages/about/about";
import Gallery from "./pages/gallery/gallery";
import Links from "./pages/links/links";
import Weather from "./pages/weather/weather";
import Profil from "./pages/profil/profil";
import Checkout from "./pages/cart/cart";
import RentInfo from "./pages/articleInfo/rentInfo";
import SaleInfo from "./pages/articleInfo/saleInfo";
import NotFound from "./pages/notFound/notFound";
import CartAccount from "./pages/cart/cart-connexion";
import CartInformation from "./pages/cart/cart-information";
import CartPayment from "./pages/cart/cart-payment";
import CartPaymentCallback from "./pages/cart/payment-callback";
import CartItems from "./pages/cart/cart-items";
import PutOnRent from "./pages/putOnRent/putOnRent";
import PutOnSale from "./pages/putOnSale/putOnSale";
import BlogOutlet from "./pages/blog/blog-outlet";
import PostsList from "./pages/blog/post-list";
import Post from "./pages/blog/post";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";
import LegalMention from "./pages/legal-mention/legal-mention";

const routes: RouteObject[] = [
    {
      path : "",
      element : <Home />,
      index : true,
    },
    {
      path : "louer/semaine",
      element : <WeeklyLocation />
    },
    {
      path : "louer/saison",
      element : <SeasonalLocation />
    },
    {
      path : "louer/annee",
      element : <AnnualLocation />
    },
    {
      path : "louer/semaine/:id",
      element : <RentInfo />
    },
    {
      path : "louer/saison/:id",
      element : <RentInfo />
    },
    {
      path : "louer/annee/:id",
      element : <RentInfo />
    },
    {
      path : "acheter",
      element : <Sale />
    },
    {
      path : "acheter/:id",
      element : <SaleInfo />
    },
    {
      path : "administration",
      element : <Administration />
    },
    {
      path : "gerance",
      element : <Managment />
    },
    {
      path : "mettre-en-location",
      element : <PutOnRent />
    },
    {
      path : "mettre-en-vente",
      element : <PutOnSale />
    },
    {
      path : "a-propos",
      element : <About />
    },
    {
      path: "galerie",
      element: <Gallery />
    },
    {
      path: "Blog",
      element: <BlogOutlet />,
      children: [
        {
          index: true,
          element: <PostsList />
        },
        {
          path: ":postId",
          element: <Post />
        }
      ]
    },
    {
      path : "liens",
      element : <Links />
    },
    {
      path : "meteo",
      element : <Weather />
    },
    {
      path : "profil",
      element : <Profil />
    },
    {
      path: "checkout",
      element: <Checkout />,
      children: [
        {
          path: "panier",
          element: <CartItems />
        },
        {
          path: "connexion",
          element: <CartAccount />
        },
        {
          path: "information",
          element: <CartInformation />
        },
        {
          path: "paiement",
          children: [
            {
              path: "",
              index: true,
              element: <CartPayment />
            },
            {
              path: "valide",
              element: <CartPaymentCallback succeeded={true} />
            },
            {
              path: "invalide",
              element: <CartPaymentCallback succeeded={false} />
            }
          ]
        }

      ]
    },
    {
      path : "contact",
      element : <Contact />
    },
    {
      path: "mentions-legals",
      element: <LegalMention />
    },
    {
      path: "politique-de-confidentialite",
      element: <PrivacyPolicy />
    },
    {
      path : "*",
      element : <NotFound />
    }
];

const LocaleRoutes = () => {

  const fullRoutes = allLocales.map(l => ({
    path: l.basename,
    children: routes
  } as unknown as RouteObject));

  return useRoutes(fullRoutes);

};

export default LocaleRoutes;
